/* html, body, #root {
  min-height: 100vh;

  overflow: hidden;
} */


@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  margin: 0;

  font-family: 'Inter', sans-serif;
  color: #000;
  
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

input, textarea {
  border: none;
  outline: none;

  font-family: inherit;
}


